import React, { useState } from 'react';

import WebComponent from 'utils/web-component';
import Modal from 'components/Modal';
import { SLIDESHOW } from 'constants/display-types';

type DisplayRemoveConfirmModalProps = {
  onRemove: any;
  setRemovingDisplay: (display: DisplayListItem | undefined) => void;
  removingDisplay?: DisplayListItem;
};

const DisplayRemoveConfirmModal = ({
  onRemove,
  removingDisplay,
  setRemovingDisplay,
}: DisplayRemoveConfirmModalProps) => {
  const [isRemoving, setRemoving] = useState(false);
  const dismiss = () => setRemovingDisplay(undefined);

  if (!removingDisplay) {
    return null;
  }

  return (
    <Modal
      title="Are you sure you want to remove this Display?"
      actionButtons={[
        <WebComponent tag="fl-button" onclick={dismiss} key="displayRemoveCancel" disabled={isRemoving} variant="secondary" size="small" tabIndex={0}>
          Cancel
        </WebComponent>,
        <span onClick={() => {
          setRemoving(true);
          onRemove(removingDisplay).then(() => {
            setRemoving(false);
            dismiss();
          });
        }}>
          <WebComponent tag="fl-button"
            key="displayRemoveConfirm"
            variant="destructive"
            size="small"
            disabled={isRemoving}
            tabIndex={0}
          >
            {isRemoving ? 'Removing…' : 'Remove'}
          </WebComponent>
        </span>,
      ]}
      dismissAction={dismiss}
    >
      <>
        {removingDisplay.type === SLIDESHOW
          ? 'All screens displaying this Slideshow won’t show the content after deleting it. However, the content won’t be removed from Flockler, and you can create new Slideshows.'
          : 'All websites using this Display layout and Embed code won’t show the content after deleting it. However, the content won’t be removed from Flockler, and you can create new Embed codes.'}
      </>
    </Modal>
  );
};

export default DisplayRemoveConfirmModal;
