import React from 'react';
import LoadingIndicator from '../LoadingIndicator';
import styles from './LogoLoader.module.scss';
import WebComponent from 'utils/web-component';

const LogoLoader = () => (
  <div className={styles.wrap}>
    <LoadingIndicator />
    <div className={styles.logo}>
      <WebComponent tag='fl-logo' />
    </div>
  </div>
);

export default LogoLoader;
