import Navigation from 'components/Navigation';
import UserProfile from 'components/UserProfile/index';
import styles from './Header.module.scss';
import { ConnectedProps, connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'redux/store';
import classNames from 'classnames';
import config from 'config';

import { ReactComponent as FlocklerStamp } from 'images/flockler-stamp.svg';
import { ReactComponent as IconSearch } from 'images/icons/icon-search.svg';
import { ReactComponent as IconLogOut } from 'images/icons/icon-log-out.svg';
import Icon from 'components/Icon';
import { getUserProfile } from 'concepts/user';
import { pathToFeedback, pathToReferral } from 'services/routes';
import { getCurrentSite, getSiteUrl, getSiteSettings } from 'concepts/site';
import { getSubscription, isExpired } from 'concepts/subscription';
import SiteSelect from './components/SiteSelect';
import WebComponent from 'utils/web-component';

type HeaderPreviewButtonProps = {
  isExpiredTrial: boolean;
  site: Partial<Site>;
};

const HeaderPreviewButton = ({ isExpiredTrial, site }: HeaderPreviewButtonProps) => {
  const { site_type } = site;

  if (site_type === 'default' || site_type === 'issue') {
    return (
      <span className={styles.headerPreviewButton}>
        <WebComponent tag="fl-button" variant="tetriary" size="small" class="nowrap" href={site?.full_url} target="_blank" rel="noopener noreferrer">
          Visit my site <Icon type="external-link" />
        </WebComponent>
      </span>
    );
  }

  if (site_type === 'api' && !isExpiredTrial) {
    return (
      <a
        href={`https://developers.flockler.com/api?site_id=${site.id}`}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.headerPreviewButton}
      >
        API documentation <Icon type="external-link" />
      </a>
    );
  }

  return null;
};

type HeaderProps = ConnectedProps<typeof connector>;

const Header = ({ user, currentSite, settings, siteUrl, subscription }: HeaderProps) => {
  const hasCurrentSite = !!currentSite?.id;

  if (!hasCurrentSite) {
    return <header className={styles.header} />;
  }

  return (
    <>
      <header className={styles.header}>
        <a
          href={`/${currentSite.site_url}`}
          aria-label="Home"
          title={`Site ID: ${currentSite.id}`}
          className="block h-full w-[25px] md:w-[32px] md:min-w-[32px] mr-2 min-w-[25px]"
        >
          <WebComponent tag="fl-logo" variant="white" reduced="true" />
        </a>
        {hasCurrentSite && (
          <>
            <SiteSelect />

            <HeaderPreviewButton
              site={currentSite}
              isExpiredTrial={!subscription?.stripe_subscription_exists && isExpired(subscription)}
            />
          </>
        )}

        <div className={styles.headerSpacer} />

        <nav className={styles.headerNav}>
          {settings?.newsroom_show_search && (
            <a href={`${config.flocklerNewsroomUrl}/${siteUrl}/search`} className={styles.headerNavItem}>
              <IconSearch className={styles.headerNavItemIcon} />
              <span className={styles.headerNavItemLabel}>Search</span>
            </a>
          )}

          {settings?.referral_program_enabled && !!siteUrl && (
            <Link to={pathToReferral(siteUrl)} className={styles.headerNavItem}>
              <Icon type="present" className={classNames(styles.headerNavItemIcon, styles.presentIcon)} />
              <span className={styles.headerNavItemLabel}>Earn a free month</span>
            </Link>
          )}
          {settings?.feedback_link_enabled && !!siteUrl && (
            <Link to={pathToFeedback(siteUrl)} className={styles.headerNavItem}>
              <Icon type="feedback" className={classNames(styles.headerNavItemIcon, styles.feedbackIcon)} />
              <span className={styles.headerNavItemLabel}>Feedback</span>
            </Link>
          )}

          <a
            href={`${process.env.REACT_APP_FLOCKLER_HELP_URL}/`}
            className={styles.headerNavItem}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="help-circle" className={styles.headerNavItemIcon} />
            <span className={styles.headerNavItemLabel}>Help center</span>
          </a>
          <a href={`${config.flocklerComUrl}/logout`} className={styles.headerNavItem}>
            <IconLogOut className={styles.headerNavItemIcon} />
            <span className={styles.headerNavItemLabel}>Log out</span>
          </a>
        </nav>
        <UserProfile user={user} siteUrl={siteUrl} />
      </header>

      <Navigation />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentSite: getCurrentSite(state),
  settings: getSiteSettings(state),
  siteUrl: getSiteUrl(state),
  subscription: getSubscription(state),
  user: getUserProfile(state),
});

const connector = connect(mapStateToProps);

export default connector(Header);
