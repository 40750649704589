const FormStatusDetail = ({
  level = 'default',
  children,
}: {
  level?: 'default' | 'success' | 'danger';
  children: React.ReactNode;
}) => {
  const classes = ['inline-flex', 'items-center', 'text-sm', 'font-medium', 'space-x-2'];

  if (level === 'default') classes.push('text-brand');
  if (level === 'success') classes.push('color-secondary-dark');
  if (level === 'danger') classes.push('color-error-dark');

  return <span className={classes.join(' ')}>{children}</span>;
};

export default FormStatusDetail;
