import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';

import useSiteUrl from 'hooks/useSiteUrl';
import { pathToNewDisplay } from 'services/routes';
import Heading from 'components/Heading';
import Page from 'components/Page';
import WebComponent from 'utils/web-component';
import DisplayMockup from './components/DisplayMockup';
import DisplayList from './components/DisplayList';
import NoticeBox from 'components/NoticeBox';
import WallVersionSwitcher from './components/WallVersionSwitcher';

import styles from './DisplayView.module.scss';

import { getCurrentSiteId, getSitesLoadingStatus, getSiteSettings } from 'concepts/site';
import {
  initDisplayView,
  getDisplayList,
  getDisplayListLoadingStatus,
  getPreviewArticlesLoading,
} from 'concepts/display-list';
import { getUserProfile } from 'concepts/user';
import { CAROUSEL, GRID, WALL } from 'constants/display-types';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import { useHistory } from 'react-router-dom';

type DisplayViewProps = {
  currentSiteId?: SiteId;
  displays: DisplayListItem[];
  isLoadingSite: boolean;
  isLoadingDisplays: boolean;
  isLoadingPreviewArticles: boolean;
  initDisplayView: any;
  settings: SiteSettings;
  user: User;
};

const DisplayView = ({
  initDisplayView,
  isLoadingSite,
  isLoadingDisplays,
  isLoadingPreviewArticles,
  displays,
  currentSiteId,
  settings,
  user,
}: DisplayViewProps) => {
  // we need extra flag because useEffect does not fire instantly...
  const [hasStartedView, setStarted] = useState(false);
  useEffect(() => {
    if (currentSiteId) {
      setStarted(true);
      // if site has no embeds yet -> redirect to /new
      initDisplayView();
    }
  }, [initDisplayView, currentSiteId]);

  const siteUrl = useSiteUrl();

  const history = useHistory();

  const isLoaderVisible = !currentSiteId || isLoadingSite || isLoadingDisplays;
  const isCreateIntroVisible =
    !!currentSiteId && !isLoadingSite && !isLoadingPreviewArticles && !isLoadingDisplays && hasStartedView;
  const isDisplayListVisible = displays.length > 0 && !isLoadingDisplays && !isLoadingPreviewArticles && hasStartedView;
  const shouldDisplayWallVersionSwitcherPromo =
    user && (settings?.default_embed_styles?.includes('wall') || settings?.default_embed_styles?.includes('wall_v1'));

  const [isWallVersionSwitcherVisible, setIsWallVersionSwitcherVisible] = useState<boolean | undefined>(false);

  useEffect(() => {
    setIsWallVersionSwitcherVisible(
      window.innerWidth >= 768 &&
        !window.localStorage.getItem('hasDismissedWallV2Switcher') &&
        shouldDisplayWallVersionSwitcherPromo
    );
  }, [shouldDisplayWallVersionSwitcherPromo, setIsWallVersionSwitcherVisible]);

  return (
    <Page className={styles.displayPage}>
      <Helmet>
        <title>Flockler {'\u203A'} Display</title>
      </Helmet>

      {isCreateIntroVisible && (
        <div className={styles.createAnotherEmbedIntro}>
          <div className={styles.leftCol}>
            <p className={styles.intro}>
              With Flockler, you can display the content you’ve gathered with multiple layouts. For the same
              subscription fee, you can create as many Walls, Grids, Carousels, and Slideshows as you like, and display
              in an unlimited number of websites, mobile apps, intranets, digital screens, etc.
            </p>

            <WebComponent tag="fl-button"
              variant="success"
              size="medium"
              title="Create a new layout"
              onClick={() => history.push(pathToNewDisplay(siteUrl))}
              ignoreclickhandling={true}
            >
              Create a new layout
            </WebComponent>
          </div>
          <div className={styles.rightCol}>
            <div className={styles.mockups}>
              <div className={styles.mockup}>
                <DisplayMockup hasAnimations={false} wrapClassName={styles.displayMockupOverride} type={GRID} />
              </div>
              <div className={styles.mockup}>
                <DisplayMockup hasAnimations={false} wrapClassName={styles.displayMockupOverride} type={WALL} />
              </div>
              <div className={styles.mockup}>
                <DisplayMockup
                  hasAnimations={false}
                  wrapClassName={`${styles.displayMockupOverride} ${styles.carousel}`}
                  type={CAROUSEL}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isDisplayListVisible && currentSiteId && (
        <>
          <div className={styles.displayListWrap}>
            {shouldDisplayWallVersionSwitcherPromo && (
              <div className={styles.newDisplayStyleAvailableNotice}>
                <div className={styles.newDisplayStyleAvailableNotice__wrapper}>
                  <NoticeBox type="neutral" className="mb-16">
                    <strong className="mr-4">
                      There’s a new and improved version of the Social Wall layout available.
                    </strong>
                    <WebComponent tag="fl-button"
                      size="small"
                      variant="primary"
                      onClick={() => setIsWallVersionSwitcherVisible(true)}
                    >
                      Upgrade for free
                    </WebComponent>
                  </NoticeBox>
                </div>
              </div>
            )}
            {isWallVersionSwitcherVisible && (
              <WallVersionSwitcher
                user={user}
                siteId={currentSiteId}
                hideAction={() => {
                  window.localStorage.setItem('hasDismissedWallV2Switcher', new Date().toISOString());
                  setIsWallVersionSwitcherVisible(false);
                }}
              />
            )}
            <Heading level="h2" type="primary">
              Previously created layouts
            </Heading>

            <DisplayList displays={displays} />
          </div>
        </>
      )}

      {isLoaderVisible && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentSiteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingDisplays: getDisplayListLoadingStatus(state),
  isLoadingPreviewArticles: getPreviewArticlesLoading(state),
  displays: getDisplayList(state),
  settings: getSiteSettings(state),
  user: getUserProfile(state),
});

const mapDispatchToProps = { initDisplayView };

export default connect(mapStateToProps, mapDispatchToProps)(DisplayView);
