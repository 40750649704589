import { useState } from 'react';
import WebComponent from 'utils/web-component';
import { useUserProfile } from 'hooks/useUserProfile';
import { useUserSiteRoles } from 'hooks/useUserSiteRoles';
import { useHistory } from 'react-router-dom';
import reviewImage from 'images/reviews/wall-review.jpg';
import NoticeBox from 'components/NoticeBox';
import PageLoader from 'components/Loader/PageLoader';

const FirstReviewFormView = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const user = useUserProfile();
  const userSiteRoles = useUserSiteRoles();
  const history = useHistory();

  const submitFirstFormName = () => {
    if (!inputValue) return;

    history.push(`?name=${encodeURIComponent(inputValue)}`);
  };

  if (!user || !userSiteRoles) {
    return <PageLoader />;
  }

  const isOwner = userSiteRoles.some((role) => role.role_name === 'owner');

  return (
    <div className="relative flex px-2 py-2">
      <div className="flex-auto space-y-5 px-4 py-6 text-slate-800 md:px-6 lg:px-8 lg:py-10">
        <h1 className="mb-4 text-2xl font-bold">Hi {user?.firstname || user?.username}! 👋</h1>
        <p>
          With Flockler, you can gather reviews and testimonials, including star ratings, text, and images. After
          collecting, you can show reviews separately or combine them with social media feeds.
        </p>

        {isOwner ? (
          <>
            <p>Start by giving a name for your form (e.g., website reviews):</p>

            <form className="mt-2" onSubmit={submitFirstFormName}>
              <div className="mb-4 space-y-2">
                <label htmlFor="feedName">Review form name</label>
                <input
                  type="text"
                  id="feedfeedNameUrl"
                  name="feedName"
                  placeholder="Website reviews"
                  required
                  autoFocus
                  onChange={(e: React.FormEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value)}
                  className="!mt-0 truncate"
                />
              </div>

              <div>
                <WebComponent tag="fl-button" disabled={!inputValue} onClick={submitFirstFormName}>
                  Create your first form
                </WebComponent>
              </div>
            </form>
          </>
        ) : (
          <NoticeBox withIcon type="neutral">
            <p>
              Using Reviews add-on requires the account owner to accept Flockler’s Data Processing Agreement. Please ask
              the account owner to create the first form for your organisation.
            </p>
          </NoticeBox>
        )}
      </div>
      <div className="relative ml-1 hidden w-[22rem] flex-none animate-[fade-in_0.5s_ease-in-out_0.5s_forwards] items-center opacity-0 [perspective:800px] md:flex">
        <img
          className="pointer-events-none absolute left-7 top-10 min-h-[279px] min-w-[327px] overflow-hidden rounded-md border-t border-slate-50 object-cover object-left-top"
          style={{
            boxShadow: '3px 10px 17px rgba(0, 0, 50,.12)',
            transform: 'rotate3d(0.1, -1, 0.1, 10deg)',
          }}
          src={reviewImage}
          alt="Flockler Review"
          width="279"
          height="327"
        />
      </div>
    </div>
  );
};

export default FirstReviewFormView;
