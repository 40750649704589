import { useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';

import { CsrfToken } from 'services/api';
import { apiKeysCreateEndpointUrl, apiKeysDestroyEndpointUrl, apiKeysIndexEndpointUrl } from 'services/routes-api-v2';

import WebComponent from 'utils/web-component';
import HelpText from 'components/HelpText';
import Icon from 'components/Icon';
import LinkExternal from 'components/LinkExternal';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import NoticeBox from 'components/NoticeBox';

type ApiKeyItem = {
  id: number;
  key: string;
  readOnly: boolean;
  updatedAt: string;
};

type ApiKeyResponse = {
  apiKeys: ApiKeyItem[];
};

const maskedApiKey = (apiKey: string) => {
  // mask first 8 characters with *
  return apiKey.replace(/^(.{26})(.*)$/, (_, first, rest) => {
    return `${first.replace(/./g, '*')}${rest}`;
  });
};

const fetcher = (url: string) =>
  fetch(url, {
    mode: 'cors',
    credentials: 'include',
  }).then((res) => res.json());

const FlocklerApiKeys = ({ site }: { site: Site }) => {
  const [apiKeys, setApiKeys] = useState<ApiKeyItem[] | undefined>();
  const [visibleApiKeys, setVisibleApiKeys] = useState<number[]>([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isDeletingApiKeyId, setIsDeletingApiKeyId] = useState<number | undefined>(undefined);

  const { data, error } = useSWR(apiKeysIndexEndpointUrl({ siteUuid: site.uuid }), fetcher);

  useMemo(() => {
    if (data) {
      setApiKeys(data.apiKeys);
    }
  }, [data]);

  const createApiKey = useMemo(() => {
    return async () => {
      setIsCreating(true);

      const res = await fetch(apiKeysCreateEndpointUrl({ siteUuid: site.uuid }), {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
          'X-CSRF-TOKEN': CsrfToken.get(),
        },
      });

      if (res.ok) {
        mutate(apiKeysIndexEndpointUrl({ siteUuid: site.uuid }));
      } else {
        const json = await res.json();
        alert(json.error);
      }

      setIsCreating(false);
    };
  }, [site]);

  const deleteApiKey = useMemo(() => {
    return async (apiKeyId: ApiKeyItem['id']) => {
      if (window.confirm('Are you sure you want to delete this API key?')) {
        setIsDeletingApiKeyId(apiKeyId);

        const res = await fetch(apiKeysDestroyEndpointUrl({ siteUuid: site.uuid, apiKeyId }), {
          method: 'DELETE',
          mode: 'cors',
          credentials: 'include',
          headers: {
            'X-CSRF-TOKEN': CsrfToken.get(),
          },
        });

        if (res.ok) {
          mutate(apiKeysIndexEndpointUrl({ siteUuid: site.uuid }), (data: ApiKeyResponse) => {
            return {
              ...data,
              apiKeys: data.apiKeys.filter((apiKey: ApiKeyItem) => apiKey.id !== apiKeyId),
            };
          });
        } else {
          const json = await res.json();
          alert(json.error);
        }

        setIsDeletingApiKeyId(undefined);
      }
    };
  }, [site]);

  const toggleApiKeyVisibility = (apiKeyId: number) => {
    if (visibleApiKeys.includes(apiKeyId)) {
      setVisibleApiKeys(visibleApiKeys.filter((id) => id !== apiKeyId));
    } else {
      setVisibleApiKeys([...visibleApiKeys, apiKeyId]);
    }
  };

  if (error) {
    return (
      <NoticeBox type="danger" withIcon>
        Error loading API keys
      </NoticeBox>
    );
  }

  if (apiKeys === undefined) {
    return <LoadingIndicator />;
  }

  return (
    <div className="space-y-5">
      <NoticeBox type="neutral">
        <div className="flex items-center gap-3">
          <span className="text-xs font-semibold uppercase tracking-wider">Endpoint</span>
          <span className="inline-block h-5 w-px bg-slate-300"></span>
          <span className="font-mono text-smaller">https://api.flockler.app/v2/{site.uuid}</span>
          <button
            type="button"
            className="text-xs font-semibold uppercase tracking-wider text-slate-600 opacity-50 transition-opacity hover:opacity-100"
            onClick={() => navigator.clipboard.writeText(`https://api.flockler.app/v2/${site.uuid}`)}
          >
            Copy
          </button>
        </div>
      </NoticeBox>

      {apiKeys?.length > 0 && (
        <div>
          {apiKeys
            .filter((apiKey) => apiKey.id !== isDeletingApiKeyId)
            .map((apiKey) => (
              <div
                key={apiKey.id}
                className="flex flex-col justify-between gap-4 border-b border-b-slate-100 py-4 sm:flex-row sm:items-center"
              >
                <div className="flex items-center gap-2">
                  <span className="rounded bg-slate-50 px-3 py-2 font-mono text-xs tracking-wider sm:text-sm">
                    {visibleApiKeys.includes(apiKey.id) ? apiKey.key : maskedApiKey(apiKey.key)}
                  </span>
                  <button
                    type="button"
                    className="text-xs font-semibold uppercase tracking-wider text-slate-600 opacity-50 transition-opacity hover:opacity-100"
                    onClick={() => navigator.clipboard.writeText(apiKey.key)}
                  >
                    Copy
                  </button>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    className="inline-flex items-center gap-1 text-sm font-semibold text-brand"
                    onClick={() => toggleApiKeyVisibility(apiKey.id)}
                  >
                    <Icon type="eye-circle" className="opacity-90" />
                    <span> {visibleApiKeys.includes(apiKey.id) ? 'Hide' : 'Show'}</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center gap-1 text-sm font-semibold text-error-dark"
                    onClick={() => deleteApiKey(apiKey.id)}
                    disabled={!!isDeletingApiKeyId}
                  >
                    <Icon type="remove-circle" className="opacity-90" />
                    <span>Delete…</span>
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}

      <div className="flex flex-col gap-5 sm:flex-row sm:items-center">
        <WebComponent tag="fl-button" variant="success" size="small" onClick={createApiKey} className="grow-0">
          {isCreating ? 'Creating…' : 'Create a new API key'}
        </WebComponent>
        <HelpText>
          Learn more from our <LinkExternal href="https://developers.flockler.com/api">API documentation</LinkExternal>
        </HelpText>
      </div>
    </div>
  );
};

export default FlocklerApiKeys;
