import React, { useState, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';

import { embedsSwitchToWallV2 } from 'services/api';
import config from 'config';

import WebComponent from 'utils/web-component';
import Highlighter from 'components/Highlighter';
import { helpScoutLinkClickAction } from 'components/HelpScoutLink';

import styles from './WallVersionSwitcher.module.scss';

type WallVersionSwitcherProps = {
  user: User;
  siteId: SiteId;
  hideAction: Function;
  updateSingleWall?: boolean;
  upgradeAction?: Function;
};

const WallVersionSwitcher = ({
  user,
  siteId,
  hideAction,
  updateSingleWall = false,
  upgradeAction,
}: WallVersionSwitcherProps) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const hideSwitcher = () => {
    setActiveStep(1);
    hideAction();
  };

  const updateDefaultWallStyle = function () {
    setIsUpdating(true);

    if (updateSingleWall && upgradeAction) {
      upgradeAction()
        .then(() => {
          alert('Layout updated!');
          window.location.reload();
        })
        .catch(() => {
          alert('Whoops! Something went wrong.');
          setIsUpdating(false);
        });
    } else {
      embedsSwitchToWallV2(siteId)
        .then(() => {
          alert('Layouts updated!');
          window.location.reload();
        })
        .catch(() => {
          alert('Whoops! Something went wrong.');
          setIsUpdating(false);
        });
    }
  };

  useEffect(() => {
    const root = document.querySelector('#root');
    root?.classList.add('overflow-hidden');
    return () => {
      const root = document.querySelector('#root');
      root?.classList.remove('overflow-hidden');
    };
  });

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
      <div className={styles.ModalScroller}>
        <div className={styles.ModalUnderlay}>
          <div className={styles.ModalWrapper}>
            <div className={styles.ModalBox} onClick={(e) => e.stopPropagation()}>
              {activeStep === 1 && (
                <WallVersionSwitcherStepOne
                  confirm={() => setActiveStep(2)}
                  cancel={hideSwitcher}
                  updateSingleWall={updateSingleWall}
                />
              )}
              {activeStep === 2 && (
                <WallVersionSwitcherStepTwo
                  confirmAnswerA={() => setActiveStep(3)}
                  confirmAnswerB={() => setActiveStep(5)}
                  cancel={hideSwitcher}
                  user={user}
                  updateSingleWall={updateSingleWall}
                />
              )}
              {activeStep === 3 && (
                <WallVersionSwitcherStepThree
                  confirmAnswerA={() => setActiveStep(4)}
                  confirmAnswerB={() => setActiveStep(6)}
                  cancel={hideSwitcher}
                  user={user}
                />
              )}
              {activeStep === 4 && (
                <WallVersionSwitcherStepFour
                  confirmAnswerA={() => setActiveStep(6)}
                  confirmAnswerB={() => setActiveStep(5)}
                  cancel={hideSwitcher}
                />
              )}
              {activeStep === 5 && (
                <WallVersionSwitcherStepFive
                  isUpdating={isUpdating}
                  confirm={updateDefaultWallStyle}
                  cancel={hideSwitcher}
                  updateSingleWall={updateSingleWall}
                />
              )}
              {activeStep === 6 && <WallVersionSwitcherStepSix confirm={() => alert('chat!')} cancel={hideSwitcher} />}
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  );
};

export default WallVersionSwitcher;

type WallVersionSwitcherStepProps = {
  confirm: Function;
  cancel: Function;
  updateSingleWall: boolean;
};

/*
 *  The 1st step introduces the features of the new wall
 */
const WallVersionSwitcherStepOne = ({ confirm, cancel, updateSingleWall }: WallVersionSwitcherStepProps) => {
  return (
    <>
      <div className={styles.wallV2BG}></div>
      <div className={styles.grid}>
        <div className={styles.gridA}>
          <h1 className={styles.title}>Say hello to the new Social&nbsp;Wall 👋</h1>
          <p>The beautiful new version of the Wall layout includes:</p>
          <ul className={styles.list}>
            <li>
              <span className="mr-2">✅</span> Uniform style with Grid & Carousel
            </li>
            <li>
              <span className="mr-2">✅</span> Modernized customizability
            </li>
            <li>
              <span className="mr-2">✅</span> Improved accessibility
            </li>
            <li>
              <span className="mr-2">✅</span> Dark mode
            </li>
          </ul>
          <p>
            …and <Highlighter>upgrading to the new version is free</Highlighter>! 🥳
          </p>
          <p>
            <strong>
              Would you like to find out if{' '}
              {updateSingleWall ? 'you can upgrade this Wall to' : 'your organization is eligible for'} the new version
              of the Social Wall layout?
            </strong>
          </p>

          <div className={styles.actions}>
            <WebComponent tag="fl-button" onClick={confirm}>Yes, tell me more</WebComponent>

            <WebComponent tag="fl-button" variant="tetriary" onClick={cancel}>
              Not right now, maybe later
            </WebComponent>
          </div>
        </div>
      </div>
    </>
  );
};

type WallVersionSwitcherStepWithOptionsProps = {
  confirmAnswerA: Function;
  confirmAnswerB: Function;
  cancel: Function;
  user?: User;
  updateSingleWall?: boolean;
};

/*
 *  The 2nd step asks whether the wall has been embedded anywhere
 */
const WallVersionSwitcherStepTwo = ({
  confirmAnswerA,
  confirmAnswerB,
  cancel,
  user,
  updateSingleWall,
}: WallVersionSwitcherStepWithOptionsProps) => {
  const [hasBeenEmbedded, setHasBeenEmbedded] = useState<boolean>();

  return (
    <>
      <div className={styles.wallV1BG}></div>
      <div className={styles.grid}>
        <div className={styles.gridA}>
          <h1 className={styles.title}>Excellent choice{user?.firstname ? `, ${user.firstname}` : ''}!</h1>

          <p>
            Let’s find out if we {updateSingleWall ? 'upgrade this Wall' : 'can move your account'} to the new version.
          </p>
          <p>
            The current version of the Wall layout looks similar to the image on the right, but your team might have
            customised its colours or look in some way.
          </p>
          <p>
            <strong>
              Have you or your team members embedded the Wall layout to websites and other digital services?
            </strong>
          </p>

          <div className={styles.options}>
            <div>
              <input
                type="radio"
                name="hasWallBeenEmbedded"
                id="wallHasBeenEmbedded"
                value="1"
                onChange={(e) => setHasBeenEmbedded(!!parseInt(e.target.value))}
              />
              <label htmlFor="wallHasBeenEmbedded">
                <strong>The Wall layout has been embedded</strong>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="hasWallBeenEmbedded"
                id="wallHasNotBeenEmbedded"
                value="0"
                onChange={(e) => setHasBeenEmbedded(!!parseInt(e.target.value))}
              />
              <label htmlFor="wallHasNotBeenEmbedded">
                <strong>
                  The Wall layout has <em>not</em> been embedded
                </strong>
              </label>
            </div>
          </div>

          <div className={styles.actions}>
            <WebComponent tag="fl-button"
              variant="primary"
              onClick={() => {
                hasBeenEmbedded ? confirmAnswerA() : confirmAnswerB();
              }}
              disabled={hasBeenEmbedded === undefined}
            >
              Next step
            </WebComponent>

            <WebComponent tag="fl-button" variant="tetriary" style={{ textDecoration: 'underline' }} onClick={cancel}>
              Actually, remind me later
            </WebComponent>
          </div>
        </div>
      </div>
    </>
  );
};

/*
 *  The 3rd step asks whether the user can view all places where the embed has been added to
 */
const WallVersionSwitcherStepThree = ({
  confirmAnswerA,
  confirmAnswerB,
  cancel,
  user,
}: WallVersionSwitcherStepWithOptionsProps) => {
  const [canViewAll, setCanViewAll] = useState<boolean>();

  return (
    <>
      <div className={styles.wallV1BG}></div>
      <div className={styles.grid}>
        <div className={styles.gridA}>
          <h1 className={styles.title}>Thanks{user?.firstname ? `, ${user.firstname}` : ''}!</h1>

          <p>A couple of more questions, if you don’t mind. 🙂</p>
          <p>
            <strong>
              If we were to make this change right now, do you have access to view all the pages and services where the
              Wall layout has been embedded?
            </strong>
          </p>

          <div className={styles.options}>
            <div>
              <input
                type="radio"
                name="canViewAllEmbeds"
                id="canViewAllEmbeddeds"
                value="1"
                onChange={(e) => setCanViewAll(!!parseInt(e.target.value))}
              />
              <label htmlFor="canViewAllEmbeddeds">
                <strong>Yes, I can view them all</strong>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="canViewAllEmbeds"
                id="cannotViewAllEmbeddeds"
                value="0"
                onChange={(e) => setCanViewAll(!!parseInt(e.target.value))}
              />
              <label htmlFor="cannotViewAllEmbeddeds">
                <strong>No, I cannot view them all</strong>
              </label>
            </div>
          </div>

          <div className={styles.actions}>
            <WebComponent tag="fl-button"
              variant="primary"
              onClick={() => {
                canViewAll ? confirmAnswerA() : confirmAnswerB();
              }}
              disabled={canViewAll === undefined}
            >
              Next step
            </WebComponent>

            <WebComponent tag="fl-button" variant="tetriary" style={{ textDecoration: 'underline' }} onClick={cancel}>
              Actually, remind me later
            </WebComponent>
          </div>
        </div>
      </div>
    </>
  );
};

/*
 *  The 4th step asks whether the user know about any customization to the embed
 */
const WallVersionSwitcherStepFour = ({
  confirmAnswerA,
  confirmAnswerB,
  cancel,
}: WallVersionSwitcherStepWithOptionsProps) => {
  const [hasAdjustedStyles, setHasAdjustedStyles] = useState<boolean>();

  return (
    <>
      <div className={styles.wallV1BG}></div>
      <div className={styles.grid}>
        <div className={styles.gridA}>
          <h1 className={styles.title}>Perfect!</h1>

          <p>It’s always better if you can double-check after the change that everything’s working smoothly.</p>
          <p>Sometimes our clients make changes to the styles (for example, colours, sizes of the elements, etc.).</p>
          <p>
            <strong>Are you aware of any changes to the styles of the current Wall layout?</strong>
          </p>

          <div className={styles.options}>
            <div>
              <input
                type="radio"
                name="hasAdjustedStyles"
                id="doesHaveAdjustedStyles"
                value="1"
                onChange={(e) => setHasAdjustedStyles(!!parseInt(e.target.value))}
              />
              <label htmlFor="doesHaveAdjustedStyles">
                <strong>Yes, we have adjusted the styles</strong>
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="hasAdjustedStyles"
                id="doesNotHaveAdjustedStyles"
                value="0"
                onChange={(e) => setHasAdjustedStyles(!!parseInt(e.target.value))}
              />
              <label htmlFor="doesNotHaveAdjustedStyles">
                <strong>I’m not aware of any adjustments</strong>
              </label>
            </div>
          </div>

          <div className={styles.actions}>
            <WebComponent tag="fl-button"
              variant="primary"
              onClick={() => {
                hasAdjustedStyles ? confirmAnswerA() : confirmAnswerB();
              }}
              disabled={hasAdjustedStyles === undefined}
            >
              Next step
            </WebComponent>

            <WebComponent tag="fl-button" variant="tetriary" style={{ textDecoration: 'underline' }} onClick={cancel}>
              Actually, remind me later
            </WebComponent>
          </div>
        </div>
      </div>
    </>
  );
};

type WallVersionSwitcherStepFiveProps = {
  isUpdating: boolean;
  confirm: Function;
  cancel: Function;
  updateSingleWall: boolean;
};

/*
 *  The 5th step asks for confimation to upgrade
 */
const WallVersionSwitcherStepFive = ({
  isUpdating,
  confirm,
  cancel,
  updateSingleWall,
}: WallVersionSwitcherStepFiveProps) => {
  return (
    <>
      <div className={styles.wallV2BG}></div>
      <div className={styles.grid}>
        <div className={styles.gridA}>
          <h1 className={styles.title}>You are good to go! 👍</h1>

          <p>
            Based on your answers, it seems that nothing is preventing you from{' '}
            {updateSingleWall ? 'upgrading this display' : 'migrating'} to the new version of the Social Wall layout.
          </p>
          <p>There’s no need to change anything on your website if you’d like to start using the new version.</p>

          <p>
            {updateSingleWall ? (
              <strong>Would you like to update this Social Wall to the new version?</strong>
            ) : (
              <strong>Would you like to update all of your existing Social Wall displays to the new version?</strong>
            )}
          </p>

          <div className={styles.actions}>
            {updateSingleWall ? (
              <>
                <WebComponent tag="fl-button" variant="success" onClick={confirm} disabled={isUpdating}>
                  {isUpdating ? 'Updating, please wait…' : 'Yes, update this Social Wall'}
                </WebComponent>
              </>
            ) : (
              <>
                <WebComponent tag="fl-button" variant="success" onClick={confirm} disabled={isUpdating}>
                  {isUpdating ? 'Updating, please wait…' : 'Yes, update my Social Walls'}
                </WebComponent>
              </>
            )}

            {!isUpdating && (
              <WebComponent tag="fl-button" variant="tetriary" style={{ textDecoration: 'underline' }} onClick={cancel}>
                Actually, remind me later
              </WebComponent>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

type WallVersionSwitcherStepSixProps = {
  confirm: Function;
  cancel: Function;
};

/*
 *  The 6th step asks the user to reach out to us via chat to provide more info
 */
const WallVersionSwitcherStepSix = ({ confirm, cancel }: WallVersionSwitcherStepSixProps) => {
  return (
    <>
      <div className={styles.wallV2BG}></div>
      <div className={styles.grid}>
        <div className={styles.gridA}>
          <h1 className={styles.title}>Let’s chat!</h1>

          <p>
            Based on your answers, it might be a good idea to have a chat with us, and we can find out together if it’s
            safe to upgrade.
          </p>

          <p>
            You can chat live with us or{' '}
            <a href={`mailto:${config.supportEmail}`} style={{ textDecoration: 'underline' }}>
              reach out to us via email
            </a>
            .
          </p>

          <div className={styles.actions}>
            <WebComponent tag="fl-button"
              variant="primary"
              onClick={(e: any) =>
                helpScoutLinkClickAction('I need help with upgrading to the new Social Wall layout', true, e)
              }
            >
              Chat with us
            </WebComponent>
            <WebComponent tag="fl-button" variant="tetriary" style={{ textDecoration: 'underline' }} onClick={cancel}>
              Actually, remind me later
            </WebComponent>
          </div>
        </div>
      </div>
    </>
  );
};
