import React from 'react';
import { Prompt } from 'react-router';
import { useFormik } from 'formik';

import {
  UPDATE_USER_PASSWORD_SUCCESS,
  UpdateUserPasswordSuccessAction,
  UpdateUserPasswordFailAction,
} from 'concepts/user';

import FormStatusDetail from 'components/FormStatusDetail';
import Icon from 'components/Icon';
import NoticeBox from 'components/NoticeBox';
import WebComponent from 'utils/web-component';

type AccountSecurityFormProps = {
  updateUserPassword: any;
  userPasswordError: any;
  isLoadingUserDetails: boolean;
  isUpdatingUserPassword: boolean;
};

const PASSWORD_MIN_LENGTH = 10;

const AccountSecurityForm = ({
  updateUserPassword,
  userPasswordError,
  isLoadingUserDetails,
  isUpdatingUserPassword,
}: AccountSecurityFormProps) => {
  const shouldDisableForm = isUpdatingUserPassword || isLoadingUserDetails;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    onSubmit: (values) => {
      updateUserPassword(values).then((action: UpdateUserPasswordSuccessAction | UpdateUserPasswordFailAction) => {
        formik.setSubmitting(false);

        if (action.type === UPDATE_USER_PASSWORD_SUCCESS) {
          formik.resetForm();
        }
      });
    },
  });

  const hasUnsavedChanges = formik.dirty;
  const passwordTooShort = formik.values.password.length < PASSWORD_MIN_LENGTH && formik.values.password.length > 0;
  const passwordsMatch =
    !formik.values.password_confirmation.length || formik.values.password === formik.values.password_confirmation;

  const invalidPassword: boolean = Boolean(userPasswordError && userPasswordError.errors.current_password);
  const invalidPasswordConfirmation: boolean = Boolean(
    userPasswordError && userPasswordError.errors.password_confirmation
  );

  return (
    <>
      <Prompt when={hasUnsavedChanges} message="You have unsaved changes, are you sure you want to leave?" />
      <form onSubmit={formik.handleSubmit} className="grid grid-cols-2 gap-6">
        <div className="col-span-2 md:col-span-1">
          <label htmlFor="currentPassword">Current password</label>
          <input
            autoComplete="current-password"
            id="currentPassword"
            name="current_password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.current_password}
            disabled={shouldDisableForm}
            required
            aria-invalid={invalidPassword}
          />
          {invalidPassword}
          {invalidPassword && <div className="form__error">Invalid password</div>}
        </div>
        <div className="col-span-2 md:col-span-1">
          <label aria-hidden="true" className="hidden sm:block"></label>
          <NoticeBox withIcon>
            Minimum length <span data-hide-on-mobile>for password</span> is 10 characters
          </NoticeBox>
        </div>
        <div className="col-span-2 md:col-span-1">
          <label htmlFor="newPassword">New password</label>
          <input
            autoComplete="new-password"
            id="newPassword"
            name="password"
            type="password"
            minLength={PASSWORD_MIN_LENGTH}
            onChange={formik.handleChange}
            value={formik.values.password}
            disabled={shouldDisableForm}
            required
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <label htmlFor="newPasswordConfirmation">Password confirmation</label>
          <input
            autoComplete="new-password"
            id="newPasswordConfirmation"
            name="password_confirmation"
            type="password"
            minLength={PASSWORD_MIN_LENGTH}
            onChange={formik.handleChange}
            value={formik.values.password_confirmation}
            disabled={shouldDisableForm}
            required
            aria-invalid={invalidPasswordConfirmation}
          />
          {invalidPasswordConfirmation && <div className="form__error">Confirmation does not match password</div>}
        </div>

        <div className="form__submit-row col-span-2">
          <WebComponent tag="fl-button" type="submit" disabled={shouldDisableForm} class="mr">
            {isUpdatingUserPassword ? 'Changing password…' : 'Change password'}
          </WebComponent>

          {passwordTooShort && (
            <FormStatusDetail level="danger">
              <Icon type="warning-circle" />
              <span>Password needs to be at least 10 characters</span>
            </FormStatusDetail>
          )}

          {!passwordTooShort && !passwordsMatch && (
            <FormStatusDetail level="danger">
              <Icon type="warning-circle" />
              <span>Password confirmation doesn’t match.</span>
            </FormStatusDetail>
          )}

          {!passwordTooShort && passwordsMatch && hasUnsavedChanges && (
            <FormStatusDetail>
              <Icon type="warning-circle" />
              <span>You have unsaved changes</span>
            </FormStatusDetail>
          )}
        </div>
      </form>
    </>
  );
};

export default AccountSecurityForm;
