import React from 'react';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import escapeRegExp from 'lodash/escapeRegExp';
import WebComponent from 'utils/web-component';

import Tags from './Tags';

import styles from './TagInput.module.scss';

const KEYS = {
  BACKSPACE: 8,
  ENTER: 13,
  TAB: 9,
  SPACE: 32,
  COMMA: 188,
};

const DELIMITER_KEY_CODES = [KEYS.TAB, KEYS.ENTER, KEYS.SPACE, KEYS.COMMA];

declare global {
  interface Window {
    clipboardData: any;
  }
}

/**
 * Convert an array of delimiter characters into a regular expression
 * that can be used to split content by those delimiters.
 */
function buildRegExpFromDelimiters(delimiters: number[]) {
  const delimiterChars = delimiters
    .map((delimiter: any) => {
      const chrCode = delimiter - 48 * Math.floor(delimiter / 48);
      return String.fromCharCode(delimiter >= 96 ? chrCode : delimiter);
    })
    .join('');
  const escapedDelimiterChars = escapeRegExp(delimiterChars);
  return new RegExp(`[${escapedDelimiterChars}]+`); // eslint-disable-line
}

function removeHashSigns(text: string) {
  return (text || '').replace(/#/g, '');
}

type TagInputProps = {
  id?: string;
  handleChange: (tags: string[]) => void;
  tags: string[];
};

const TagInput = ({ handleChange, tags, id }: TagInputProps) => {
  const [tagInputText, setTagInputText] = React.useState('');

  const handleAddition = (tag: string | string[]) => {
    const currentTags = tags || [];
    const updatedTags = Array.isArray(tag) ? [...currentTags, ...tag] : [...currentTags, tag];
    handleChange(updatedTags);
  };

  const handleDelete = (tag: string) => {
    const updatedTags = without(tags, tag);
    handleChange(updatedTags);
  };

  const isTagUnique = (text: string) => {
    return !tags.some((tag: string) => text.toUpperCase() === tag.toUpperCase());
  };

  const checkIsTagValid = (tag: string) => isTagUnique(tag) && tag.length > 0;

  const removeTag = (tag: string) => {
    handleDelete(tag);
  };

  const addTag = (text: string) => {
    const cleanTagText = removeHashSigns(text);

    if (checkIsTagValid(cleanTagText)) {
      handleAddition(cleanTagText);
      setTagInputText('');
    }
  };

  const addTags = (tags: string[]) => {
    // Add unique and valid tags only
    const validTags = uniq(tags.map(removeHashSigns)).filter(checkIsTagValid);

    if (validTags.length) {
      handleAddition(validTags);
    }

    setTagInputText('');
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;

    setTagInputText(value.trim());
  };

  const handleAddClick = () => {
    addTag(tagInputText);
  };

  const handleKeyDown = (event: any) => {
    const keyCode = event.which || event.keyCode;

    // Remove last on backspace
    if (keyCode === KEYS.BACKSPACE && tagInputText === '' && tags.length) {
      removeTag(tags[tags.length - 1]);
    }

    // Add on whitelisted keycodes
    if (DELIMITER_KEY_CODES.includes(keyCode) && tagInputText !== '') {
      // Add tag
      addTag(tagInputText);
      // This prevents key press from going to input handleChange
      event.preventDefault();
    }
  };

  const handlePaste = (event: any) => {
    // This prevents pasted text from appearing to input
    event.preventDefault();

    // Get pasted text
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData('text');

    // Parse tags from pasted text
    const delimiterRegExp = buildRegExpFromDelimiters(DELIMITER_KEY_CODES);
    const tags = pastedText.split(delimiterRegExp);

    // Add multiple tags
    addTags(tags);
  };

  const isTagValid = checkIsTagValid(tagInputText);

  return (
    <div className={styles.tagInput}>
      <Tags tags={tags} onRemove={removeTag} newTag={tagInputText}>
        <div className={styles.tagInputFieldWrapper}>
          <input
            id={id}
            tabIndex={0}
            type="text"
            className={styles.tagInputField}
            placeholder="Enter a tag..."
            value={tagInputText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            onBlur={handleAddClick}
          />
          {tagInputText && (
            <span className={styles.addButton}>
              <WebComponent size='small' tag='fl-button' type="button" disabled={!isTagValid} onClick={handleAddClick} css="user-select: none;">
                Add
              </WebComponent>
            </span>
          )}
        </div>
      </Tags>
    </div>
  );
};

export default TagInput;
