import maxBy from 'lodash/maxBy';

export const findLatestServiceAccount = (service: MediaTrackerService, accounts?: UserSocialMediaAccount[]) => {
  const serviceAccounts = (accounts || []).filter((account) => {
    if (service === 'instagram_graph_api') {
      return account.type === 'FacebookAccount';
    } else if (service === 'tiktok') {
      return account.channel === 'tiktok';
    } else if (service === 'linkedin') {
      return account.channel === 'linkedin';
    } else if (service === 'google_review') {
      return account.channel === 'googlemybusiness';
    } else if (service === 'instagram_basic_display') {
      return account.channel === 'instagram';
    }

    return !!account.type && account.type.toLowerCase().indexOf(service) !== -1;
  });

  return maxBy(serviceAccounts, 'connected_at');
};
