import { useState } from 'react';
import { format, formatDistance } from 'date-fns';
import { urlToArticleEditor } from 'services/routes';
import { useInterval } from 'usehooks-ts';
import startCase from 'lodash/startCase';
import WebComponent from 'utils/web-component';

import Icon from 'components/Icon';

const Reviewer = ({
  index,
  site,
  reviewer,
  deleteAction,
  toggleSelect,
  selected,
  isDeletingSomeReviewer,
}: {
  index: number;
  site: Site;
  reviewer: Reviewer;
  deleteAction: () => void;
  toggleSelect: () => void;
  selected: boolean;
  isDeletingSomeReviewer: boolean;
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [detailsVisible, setDetailsVisible] = useState(false);
  const { firstName, lastName, email, termsAccepted, customConsent, createdAt, review } = reviewer;

  useInterval(() => {
    setCurrentTime(new Date());
  }, 30000);

  const toggleDetailVisibility = () => {
    setDetailsVisible(!detailsVisible);
  };

  return (
    <div
      className={`
        relative border border-slate-200 border-x-transparent border-t-transparent p-4
        ${index % 2 === 0 ? 'bg-slate-50/50' : 'bg-transparent'}
        flex items-start hover:rounded
        hover:ring-1 hover:ring-brand
    `}
    >
      <div className="w-8 shrink-0 pt-1">
        <button
          type="button"
          className={`
            relative top-px block h-4 w-4 rounded border outline-none ring-1
            ${
              selected
                ? 'border-white bg-brand ring-1 ring-brand focus-within:ring-offset-2'
                : 'border-slate-500 bg-white ring-transparent'
            }
            focus-visible:border-white focus-visible:ring-brand
            disabled:cursor-not-allowed disabled:opacity-25
          `}
          onClick={() => toggleSelect()}
          aria-label={selected ? 'Deselect' : 'Select'}
          disabled={isDeletingSomeReviewer}
        ></button>
      </div>
      <div className="grow">
        <div className="grid grid-cols-3 gap-4 py-1 text-sm leading-tight">
          <div>
            {firstName || lastName ? (
              <div title={[firstName, lastName].join(' ')} className="overflow-hidden text-ellipsis whitespace-nowrap">
                {firstName} {lastName}
              </div>
            ) : (
              <div className="text-slate-400">No name given</div>
            )}
          </div>
          <div>
            {email ? (
              <a
                href={`mailto:${email}`}
                title={email}
                className="
                inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-sm text-inherit
                hover:text-brand focus:outline-none focus-visible:no-underline focus-visible:ring-1
                focus-visible:ring-brand focus-visible:ring-offset-4
              "
              >
                {email}
              </a>
            ) : (
              <div className="text-slate-400">No email given</div>
            )}
          </div>
          <div>
            <time title={format(new Date(createdAt), 'yyyy-MM-dd HH:mm:ss')}>
              <div className="first-letter:uppercase">{formatDistance(new Date(createdAt), currentTime)} ago</div>
            </time>
          </div>
        </div>

        <button
          title={detailsVisible ? 'Hide details' : 'Show details'}
          type="button"
          onClick={toggleDetailVisibility}
          className="absolute
          top-2 right-0 h-11 w-10 rounded text-slate-400 outline-none transition-colors
          hover:text-brand focus-visible:text-brand focus-visible:ring-1 focus-visible:ring-current
        "
          aria-label={detailsVisible ? 'Hide details' : 'Show details'}
        >
          <Icon type={detailsVisible ? 'angle-down' : 'angle-right'} className="pointer-events-none h-3 w-3" />
        </button>

        {detailsVisible && (
          <>
            <div className="my-4 space-y-4 border-y border-dashed py-6">
              <div className="grid grid-cols-4 gap-4 ">
                <figure className="aspect-h-1 aspect-w-1 h-0 w-full overflow-hidden rounded-lg bg-slate-100 ">
                  {reviewer.review.coverUrl ? (
                    <img loading="lazy" src={reviewer.review.coverUrl} alt="" className="h-full w-full object-cover" />
                  ) : (
                    <div className="flex items-center justify-center text-xs uppercase tracking-widest text-slate-400">
                      No image
                    </div>
                  )}
                </figure>
                <div className="col-span-3 space-y-6">
                  <div className="space-y-1">
                    <div className="text-smaller font-semibold text-slate-800">Review text</div>
                    <div className="space-y-4 prose-p:text-sm" dangerouslySetInnerHTML={{ __html: review.body }}></div>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <div className="text-smaller font-semibold text-slate-800">Rating</div>
                      {review.rating ? (
                        <span className="inline-block origin-left scale-110 transform">
                          {Array.from({ length: review.rating }).map(() => '⭐')}
                        </span>
                      ) : (
                        <span className="text-slate-400">No rating given</span>
                      )}
                    </div>
                    <div className="space-y-1">
                      <div className="text-smaller font-semibold text-slate-800">Terms and conditions</div>
                      <div className="text-smaller">
                        {termsAccepted === null ? (
                          <span className="text-slate-400" title="Form did not include an accept terms checkbox">
                            N/A
                          </span>
                        ) : (
                          <div
                            className={`inline-flex items-center space-x-2 font-medium ${
                              termsAccepted ? 'text-green-700' : 'text-red-700'
                            }`}
                          >
                            <Icon type={termsAccepted ? 'checkmark-circle' : 'ban'} />
                            <span className="text-slate-800">{termsAccepted ? 'Accepted' : 'Declined'}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="space-y-1">
                      <div className="text-smaller font-semibold text-slate-800">Custom consent</div>
                      <div className="text-smaller">
                        {customConsent === null ? (
                          <span className="text-slate-400" title="Form did not include a custom consent checkbox">
                            N/A
                          </span>
                        ) : (
                          <div
                            className={`inline-flex items-center space-x-2 font-medium ${
                              customConsent ? 'text-green-700' : 'text-red-700'
                            }`}
                          >
                            <Icon type={customConsent ? 'checkmark-circle' : 'ban'} />
                            <span className="text-slate-800">{customConsent ? 'Accepted' : 'Declined'}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {(
                    [
                      'customFieldA',
                      'customFieldB',
                      'customFieldC',
                      'customFieldD',
                      'customFieldE',
                    ] as ReviewerCustomFieldKey[]
                  ).map((fieldId) => {
                    if (!reviewer[fieldId]) return null;

                    const fieldDetails = reviewer[fieldId] as CustomFieldDetails;
                    const customFieldLabel = fieldDetails.label;
                    const customFieldValue = fieldDetails.value;

                    return (
                      <div>
                        <div className="text-smaller font-semibold text-slate-800">
                          {customFieldLabel === fieldId ? startCase(fieldId) : customFieldLabel}
                        </div>
                        <div className="whitespace-pre-line text-sm">{customFieldValue}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="space-x-6 text-sm">
              <WebComponent
                tag="fl-button"
                href={urlToArticleEditor(site.site_url, review.sectionId, review.id)}
              >
                <Icon type="eye-circle" />
                <span>View in editor</span>
              </WebComponent>

              <WebComponent
                tag='fl-button'
                class="ml"
                variant="ghost-destructive"
                onClick={() => deleteAction()}
                disabled={isDeletingSomeReviewer}
              >
                <Icon type="remove-circle" />
                <span>Remove reviewer’s information</span>
              </WebComponent>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Reviewer;
